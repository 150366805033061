import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["cover"];
  
  checkCover(event) {
    // Allow unchecking; i.e. do nothing
    if (event.currentTarget.checked === false) {
      return;
    }
    // Uncheck all the other boxes
    this.coverTargets.forEach((coverCheckbox) => {
      coverCheckbox.checked = event.currentTarget == coverCheckbox;
    });
  }
}
