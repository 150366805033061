import ApplicationController from "./application_controller";
import { Editor, defaultValueCtx, rootCtx } from '@milkdown/core';
import { history, redoCommand, undoCommand } from '@milkdown/plugin-history';
import { clipboard } from '@milkdown/plugin-clipboard';
import { indent } from '@milkdown/plugin-indent';
import { listener, listenerCtx } from "@milkdown/plugin-listener";
import { DirectUpload } from '@rails/activestorage';
import { callCommand, getMarkdown, replaceAll } from "@milkdown/utils";
import {
  commonmark,
  toggleEmphasisCommand,
  toggleStrongCommand,
  wrapInBulletListCommand,
  wrapInOrderedListCommand,
  wrapInHeadingCommand,
  toggleLinkCommand,
  blockquoteAttr,
  imageAttr,
} from "@milkdown/preset-commonmark";
import {
  gfm,
  toggleStrikethroughCommand,
} from "@milkdown/preset-gfm";
import { layer, icon } from "@fortawesome/fontawesome-svg-core";
import { faBold, faH, faH1, faH2, faH3, faH4, faH5, faItalic, faLink, faListDots, faListNumeric, faRedo, faSlash, faStrikethrough, faUndo } from "@fortawesome/pro-solid-svg-icons";
import { cursor } from "@milkdown/plugin-cursor";
import { upload, uploadConfig } from "@milkdown/plugin-upload";
import { faMarkdown } from "@fortawesome/free-brands-svg-icons";

export default class extends ApplicationController {
  static targets = ['editor', 'input', 'toolbar', 'linkModal', 'linkLabel', 'linkHref'];

  tooltipObjects = [];

  async uploader(files, schema) {
    // Only select valid images from given input
    const images = Array.from(files).filter((file) => file?.type?.includes('image') === true);

    const uploadFilePromise = (image) => {
      return new Promise((resolve, reject) => {
        const directUploadHandler = new DirectUpload(image, '/media/direct_uploads');
        directUploadHandler.create((error, blob) => {
          if (error) {
            return reject(error);
          }
          resolve({
            src: `/media/blobs/${blob.signed_id}/${image.name}`,
            ...blob
          });
        });
      });
    }

    return Promise.all(
      images.map(
        (image) => uploadFilePromise(image)
      )
    ).then((blobs) => blobs.map(
      ({ src, signed_id }) => schema.nodes.image.createAndFill(
        {
          src,
          title: signed_id,
          // We use the alt text to decide styles on render
          alt: 'align-left',
        }
      )
    ));
  }

  async connect() {
    this.editor = await Editor
      .make()
      .config((ctx) => {
        // Mount on editorTarget
        ctx.set(rootCtx, this.editorTarget);
        // Parse the existing value and pass to Milkdown
        if (this.inputTarget.value != '') {
          ctx.set(defaultValueCtx, this.inputTarget.value);
        }
        // Update the hidden input with the markdown string
        ctx.get(listenerCtx).markdownUpdated((_ctx, markdown, _prevMarkdown) => {
          this.inputTarget.value = markdown;
        });
        // Configure the upload plugin
        ctx.update(uploadConfig.key, (prev) => ({
          ...prev,
          uploader: this.uploader,
        }));
        // Add attributes to certain tags
        // Blockquotes match Bootstrap style
        ctx.set(blockquoteAttr.key, (_node) => ({
          class: 'blockquote font-italic'
        }));
        // Images need responsiveness to fit within window
        ctx.set(imageAttr.key, ({ attrs: { alt, src, title } }) => {
          // We store the alignment commands in the alt, along with other CSS
          const classesFromAltCommands = alt.split(' ').map((altCommand) => {
            if (altCommand.startsWith('align-')) {
              const [_, alignmentSide] = altCommand.split('-');
              if (alignmentSide === 'center') {
                return 'mx-auto d-block';
              }
              if (alignmentSide === 'right') {
                return 'float-end';
              }
            }
          });

          return { class: `img-fluid img-thumbnail ${classesFromAltCommands.join(' ')}` };
        });
      })
      .use(commonmark)
      .use(history)
      .use(clipboard)
      .use(indent)
      .use(gfm)
      .use(listener)
      .use(cursor)
      .use(upload)
      .create();
    this.richTextModeToolbar();

    // Handle styling
    this.inputTarget.style.display = 'none';
    this.toolbarTarget.classList.add('hstack');
    this.toolbarTarget.classList.add('gap-2');
  }

  disconnect() {
    this.editor.destroy(true);
  }

  /**
   * Create a button element.
   * Provide a single faIcon or an array of faIcons to stack them
   *
   * @return {HTMLElement}
   */
  createButton(label, faIcon, command, payload) {
    const ButtonElement = document.createElement('button');
    ButtonElement.classList.add('btn');
    ButtonElement.classList.add('btn-sm');
    ButtonElement.classList.add('btn-outline-dark');
    ButtonElement.setAttribute('type', 'button');
    ButtonElement.setAttribute('data-bs-toggle', 'tooltip');
    ButtonElement.setAttribute('data-bs-placement', 'top');
    ButtonElement.setAttribute('title', label);
    if (Array.isArray(faIcon)) {
      ButtonElement.appendChild(layer((push) => {
        faIcon.forEach((iconObj) => push(icon(iconObj)));
      }).node[0]);
    } else {
      ButtonElement.appendChild(icon(faIcon).node[0]);
    }
    ButtonElement.addEventListener("click", (event) => {
      // Prevent page navigation
      event.preventDefault();
      if (typeof command === 'function') {
        command();
      } else {
        this.editor.action(callCommand(command, payload));
      }
    });
    this.tooltipObjects.push(new bootstrap.Tooltip(ButtonElement, { boundary: 'window' }));

    return ButtonElement;
  }

  get linkModal() {
    return bootstrap.Modal.getOrCreateInstance(this.linkModalTarget);
  }

  handleInsertLink() {
    const payload = {
      'href': this.linkHrefTarget.value,
      'title': this.linkLabelTarget.value,
    };
    this.editor.action(callCommand(toggleLinkCommand.key, payload));
    this.linkHrefTarget.value = '';
    this.linkLabelTarget.value = '';
    this.linkModal.hide();
  }

  handleToggleMarkdown() {
    if (this.inputTarget.style.display === 'none') {
      this.inputTarget.style.display = '';
      this.editorTarget.style.display = 'none';
      this.markdownModeToolbar();
      // Highlight is manually managed; we need to clear its escaped value
      this.inputTarget.value = this.inputTarget.value.replaceAll('\\==', '==');
    } else {
      this.inputTarget.style.display = 'none';
      this.editorTarget.style.display = '';
      // Update RTE with Markdown representation
      this.editor.action(replaceAll(this.inputTarget.value, true));
      this.richTextModeToolbar();
    }
  }

  createDivider() {
    const divider = document.createElement('div');
    divider.classList.add('vr');
    return divider;
  }

  clearToolbar() {
    // Need to dispose the Tooltip object to ensure it does not persist on the DOM
    // As well as prevent memory leaks
    while (this.tooltipObjects.length > 0) {
      this.tooltipObjects.pop().dispose();
    }
    // Only after all bound Tooltip objects are cleared, then remove each node
    while (this.toolbarTarget.firstChild) {
      this.toolbarTarget.removeChild(this.toolbarTarget.lastChild);
    }
  }

  markdownModeToolbar() {
    this.clearToolbar();
    const alertElement = document.createElement('div');
    alertElement.classList.add('alert');
    alertElement.classList.add('alert-warning');
    alertElement.classList.add('d-flex');
    alertElement.classList.add('align-items-center');
    alertElement.appendChild(icon(faMarkdown).node[0]);
    const alertText = document.createElement('div');
    alertText.classList.add('ms-1');
    alertText.textContent = "Markdown Editing mode. Click this banner to return to Rich Text editing.";
    alertElement.appendChild(alertText);
    this.toolbarTarget.appendChild(alertElement);
    alertElement.addEventListener("click", (_event) => {
      this.handleToggleMarkdown();
    });
  }

  richTextModeToolbar() {
    this.clearToolbar();
    const buttons = [
      [
        this.createButton('Bold', faBold, toggleStrongCommand.key),
        this.createButton('Italic', faItalic, toggleEmphasisCommand.key),
        this.createButton('Strikethrough', faStrikethrough, toggleStrikethroughCommand.key),
      ],
      [
        this.createButton('Remove Heading', [faH, faSlash], wrapInHeadingCommand.key, 0),
        this.createButton('Heading 1', faH1, wrapInHeadingCommand.key, 1),
        this.createButton('Heading 2', faH2, wrapInHeadingCommand.key, 2),
        this.createButton('Heading 3', faH3, wrapInHeadingCommand.key, 3),
        this.createButton('Heading 4', faH4, wrapInHeadingCommand.key, 4),
        this.createButton('Heading 5', faH5, wrapInHeadingCommand.key, 5),
      ],
      [
        this.createButton('Link', faLink, () => this.linkModal.show()),
        // Disabled blockquote until we can figure out how to add .blockquote class
        // this.createButton('Blockquote', faBlockQuote, wrapInBlockquoteCommand.key),
      ],
      [
        this.createButton('Bullet List', faListDots, wrapInBulletListCommand.key),
        this.createButton('Ordered List', faListNumeric, wrapInOrderedListCommand.key),
      ],
      [
        this.createButton('Undo', faUndo, undoCommand.key),
        this.createButton('Redo', faRedo, redoCommand.key),
      ],
      [
        this.createButton('Show Markdown', faMarkdown, () => this.handleToggleMarkdown()),
      ]
    ];
    buttons.forEach((buttonGroup) => {
      const group = document.createElement('div');
      group.classList.add('hstack');
      group.classList.add('gap-1');
      buttonGroup.forEach((button) => group.appendChild(button));
      this.toolbarTarget.appendChild(group);
      this.toolbarTarget.appendChild(this.createDivider());
    });
  }
}