import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    'modal',
    'title',
    'image',
    'name',
    'certifications',
    'subtitle',
    'biography',
  ];

  get modal() {
    return bootstrap.Modal.getOrCreateInstance(this.modalTarget);
  }

  initialize() {
    // If the hash is supplied, display the modal
    if (window.location.hash) {
      const elementId = window.location.hash.split('#')[1];
      if (elementId == null || elementId.length < 1) {
        return;
      }
      const modalElement = document.getElementById(elementId);
      if (modalElement == null) {
        return;
      }
      const modalCardElement = modalElement.querySelector(':scope .card');
      if (modalCardElement == null) {
        return;
      }

      this.extractContent(modalCardElement);
      this.modal.show();
    }
  }

  showModal(event) {
    this.extractContent(event.currentTarget);
    this.modal.show();
  }

  extractContent(modalCardElement) {
    const imageElement = modalCardElement.querySelector(':scope .image');
    const data = {
      title: modalCardElement.getAttribute('data-title'),
      subtitle: modalCardElement.querySelector(':scope .subtitle')?.innerHTML,
      name: modalCardElement.querySelector(':scope .name')?.innerHTML,
      biography: modalCardElement.querySelector(':scope .biography')?.innerHTML,
      certifications: modalCardElement.querySelector(':scope .certifications')?.innerHTML,
      image: imageElement?.dataset?.fullsize ?? imageElement?.getAttribute('src'),
    };

    this.setModalContent(data);
  }

  setModalContent(data) {
    this.titleTarget.innerHTML = data.title;
    this.subtitleTarget.innerHTML = data.subtitle;
    this.nameTarget.innerHTML = data.name;
    this.biographyTarget.innerHTML = data.biography ?? '';
    this.certificationsTarget.innerHTML = data.certifications ?? '';
    this.imageTarget.setAttribute('src', data.image);
    this.imageTarget.setAttribute('alt', data.name);
  }
}
