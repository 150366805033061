import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["avatar"];
  initialize() {
    this.avatarTargets.forEach((element) => {
      element.style.backgroundColor = this.stringToHslColor(element.textContent, 50, 50);
    });
  }

  // Adapted from https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
  stringToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }
}
