import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  initialize() {
    if (turnstile != null) {
      turnstile.render('.cf-turnstile');
    }
  }
}
