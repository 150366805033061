import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

// Configure FontAwesome
import { config, library, dom } from '@fortawesome/fontawesome-svg-core';
// Change the config to fix the flicker
config.mutateApproach = 'sync'
// Add icon library
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faHandshake } from '@fortawesome/pro-solid-svg-icons/faHandshake';
import { faChartLine } from '@fortawesome/pro-solid-svg-icons/faChartLine';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faPuzzlePiece } from '@fortawesome/pro-solid-svg-icons/faPuzzlePiece';
import { faHandPointer } from '@fortawesome/pro-solid-svg-icons/faHandPointer';
import { faBriefcase } from '@fortawesome/pro-solid-svg-icons/faBriefcase';
import { faLaptop } from '@fortawesome/pro-solid-svg-icons/faLaptop';
import { faFileContract } from '@fortawesome/pro-solid-svg-icons/faFileContract';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faSquare } from '@fortawesome/pro-solid-svg-icons/faSquare';
import { faTrophy } from '@fortawesome/pro-solid-svg-icons/faTrophy';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faAndroid } from '@fortawesome/free-brands-svg-icons/faAndroid';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons/faInstagramSquare';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage';
import { faImages } from '@fortawesome/pro-solid-svg-icons/faImages';
import { faVideo } from '@fortawesome/pro-solid-svg-icons/faVideo';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar';
import { faBold } from '@fortawesome/pro-solid-svg-icons/faBold';
import { faItalic } from '@fortawesome/pro-solid-svg-icons/faItalic';
import { faUnderline } from '@fortawesome/pro-solid-svg-icons/faUnderline';
import { faStrikethrough } from '@fortawesome/pro-solid-svg-icons/faStrikethrough';
import { faHeading } from '@fortawesome/pro-solid-svg-icons/faHeading';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons/faQuoteLeft';
import { faListUl } from '@fortawesome/pro-solid-svg-icons/faListUl';
import { faListOl } from '@fortawesome/pro-solid-svg-icons/faListOl';
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faTable } from '@fortawesome/pro-solid-svg-icons/faTable';
import { faColumns } from '@fortawesome/pro-solid-svg-icons/faColumns';
import { faArrowsAlt } from '@fortawesome/pro-solid-svg-icons/faArrowsAlt';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faListAlt } from '@fortawesome/pro-solid-svg-icons/faListAlt';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt';
import { faTags } from '@fortawesome/pro-solid-svg-icons/faTags';
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag';
import { faCat } from '@fortawesome/pro-solid-svg-icons/faCat';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons/faMinusCircle';
import { faHospitals } from '@fortawesome/pro-solid-svg-icons/faHospitals';
import { faLifeRing } from '@fortawesome/pro-solid-svg-icons/faLifeRing';
import { faMoneyBillWheat } from '@fortawesome/pro-solid-svg-icons/faMoneyBillWheat';
import { faBullseyePointer, faCompass, faDiploma, faFileShield, faFlowerDaffodil, faGarage, faGaugeCircleBolt, faGears, faH1, faH2, faH3, faH4, faH5, faHeadset, faHouseUser, faIslandTropical, faMessagesQuestion, faMobile, faMoneyCheckDollar, faNewspaper, faPersonChalkboard, faRankingStar, faRobot, faSchool, faShareNodes, faSignature, faToolbox, faUserShield } from "@fortawesome/pro-solid-svg-icons";
library.add(
  faUser,
  faBars,
  faUsers,
  faHandshake,
  faChartLine,
  faSearch,
  faPuzzlePiece,
  faHandPointer,
  faBriefcase,
  faLaptop,
  faFileContract,
  faEye,
  faCircle,
  faSquare,
  faTrophy,
  faTimes,
  faArrowLeft,
  faStar,
  faClock,
  faCaretRight,
  faSpinner,
  faApple,
  faAndroid,
  faFacebookSquare,
  faLinkedin,
  faInstagramSquare,
  faWhatsapp,
  faImage,
  faImages,
  faCalendar,
  faVideo,
  faCheck,
  faBold,
  faItalic,
  faUnderline,
  faStrikethrough,
  faHeading,
  faQuoteLeft,
  faListUl,
  faListOl,
  faLink,
  faTable,
  faColumns,
  faArrowsAlt,
  faQuestionCircle,
  faListAlt,
  faTrash,
  faTrashAlt,
  faTags,
  faTag,
  faCat,
  faPlusCircle,
  faMinusCircle,
  faHospitals,
  faLifeRing,
  faMoneyBillWheat,
  faH1,
  faH2,
  faH3,
  faH4,
  faH5,
  faPersonChalkboard,
  faHouseUser,
  faUserShield,
  faGarage,
  faIslandTropical,
  faMoneyCheckDollar,
  faMessagesQuestion,
  faBullseyePointer,
  faNewspaper,
  faFlowerDaffodil,
  faSchool,
  faDiploma,
  faCompass,
  faRobot,
  faRankingStar,
  faMobile,
  faGears,
  faHeadset,
  faGaugeCircleBolt,
  faToolbox,
  faShareNodes,
  faSignature,
  faFileShield
);

dom.watch();

export { application }
