import ApplicationController from "./application_controller";
import { Datepicker } from 'vanillajs-datepicker';

const previousClaimFieldIDs = [
  'form_motor_claim_description',
  'form_motor_claim_amount',
  'form_motor_claim_date'
];

const defaultDatepickerSettings = {
  buttonClass: 'btn',
  autohide: true,
  format: 'dd/mm/yyyy',
};
export default class extends ApplicationController {
  static targets = ['type', 'claimPrevious', 'departureDate', 'returnDate', 'dateOfBirth', 'travel', 'motor'];

  get travel() {
    return bootstrap.Collapse.getOrCreateInstance(this.travelTarget);
  }

  get motor() {
    return bootstrap.Collapse.getOrCreateInstance(this.motorTarget);
  }

  get dateOfBirth() {
    if (!this.dateOfBirthDatepicker) {
      this.datepickerDateOfBirth = new Datepicker(this.dateOfBirthTarget, {
        maxDate: 'today',
        title: 'Date of Birth',
        ...defaultDatepickerSettings,
      });
    }
    return this.dateOfBirthDatepicker;
  }

  get dateDeparture() {
    if (!this.datepickerDepartureDate) {
      this.datepickerDepartureDate = new Datepicker(this.departureDateTarget, {
        minDate: 'today',
        todayButton: true,
        todayButtonMode: 1,
        title: 'Departure Date',
        ...defaultDatepickerSettings,
      });
    }
    return this.datepickerDepartureDate;
  }

  get dateReturn() {
    if (!this.datepickerReturnDate) {
      this.datepickerReturnDate = new Datepicker(this.returnDateTarget, {
        title: 'Return Date',
        ...defaultDatepickerSettings,
      });
    }
    return this.datepickerReturnDate;
  }

  initialize() {
    // This is the only datepicker field that is common to both Travel and Motor
    this.dateOfBirth;
    // Initialise collapse elements
    this.travel.hide();
    this.motor.hide();
    // Disable items
    this.returnDateTarget.setAttribute('disabled', 'true');
    this.changeClaimPrevious();
  }

  handleDepartureDateChange(event) {
    const minDate = event.detail.date;
    if (minDate != null) {
      this.dateReturn.setOptions({ minDate });
      this.returnDateTarget.removeAttribute('disabled');
      if (this.dateReturn.getDate() < minDate) {
        // If the current return date is before the departure date, reset the field
        this.dateReturn.setDate({ clear: true });
      }
    } else {
      this.returnDateTarget.setAttribute('disabled', 'true');
    }
  }

  selectType({currentTarget}) {
    if (currentTarget.value == 'Travel') {
      this.travel.show();
      this.motor.hide();
      // Initialise Departure date
      this.dateDeparture;
    } else {
      this.travel.hide();
      this.motor.show();
    }
  }

  changeClaimPrevious() {
    previousClaimFieldIDs.forEach((fieldID) => {
      const element = document.getElementById(fieldID);
      if (element != null) {
        if (this.claimPreviousTarget.checked) {
          element.removeAttribute('disabled');
        } else {
          element.setAttribute('disabled', 'true');
        }
      }
    })
  }

  changeDepartureDate() {
    if (this.departureDateTarget.value)
      this.returnDateTarget.removeAttribute('disabled');
    const datepickerReturnDate = new Datepicker(this.returnDateTarget, {
      todayButton: true,
      todayButtonMode: 1,
      title: 'Return Date',
      minDate: this.departureDateTarget.value,
      ...defaultDatepickerSettings,
    });
  }
}
