import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "name", "shareForm", "shareModal", "shareEventName", "cover" ];
  formAction = '';

  initialize() {
    this.formAction = $(this.shareFormTarget).attr('action');
  }

  shareSpreadsheet(event) {
    $(this.shareEventNameTarget).html($(event.currentTarget).data('event-name'));
    $(this.shareFormTarget).attr('action', this.formAction.replace('REPLACE_ME', $(event.currentTarget).data('event-id')));
    $(this.shareModalTarget).modal('show');
  }

  checkCover(event) {
    // Allow unchecking; i.e. do nothing
    if (event.currentTarget.checked === false) {
      return;
    }
    // Uncheck all the other boxes
    this.coverTargets.forEach((coverCheckbox) => {
      coverCheckbox.checked = event.currentTarget == coverCheckbox;
    });
  }
}
