import ApplicationController from "./application_controller";
import MobileDetect from 'mobile-detect';

export default class extends ApplicationController {
  static targets = [
    "displayMobile",
    "displayApple",
    "displayAndroid",
    "displayDesktop"
  ]

  initialize() {
    // first hide all
    this.hideAll();

    // Detect mobile type
    const md = new MobileDetect(window.navigator.userAgent);

    if (md.mobile('iPhone')) {
      this.showMobileTargets();
      this.displayAppleTargets.forEach((element) => element.style.display = '');
    } else if (md.mobile('Android')) {
      this.showMobileTargets();
      this.displayAndroidTargets.forEach((element) => element.style.display = '');
    } else {
      this.displayDesktopTargets.forEach((element) => element.style.display = '');
    }
  }

  hideAll() {
    this.displayMobileTargets.forEach((element) => element.style.display = 'none');
    this.displayAppleTargets.forEach((element) => element.style.display = 'none');
    this.displayAndroidTargets.forEach((element) => element.style.display = 'none');
    this.displayDesktopTargets.forEach((element) => element.style.display = 'none');
  }

  showMobileTargets() {
    this.displayMobileTargets.forEach((element) => element.style.display = '');
  }

}
