// Initialise Sentry if not dev at the very start to track JS loading errors
import * as Sentry from '@sentry/browser';
if (ENVIRONMENT !== 'dev' && ENVIRONMENT !== 'development') {
  window.Sentry = Sentry;
  Sentry.onLoad(function () {
    if (window.SENTRY_DSN) {
      Sentry.init({
        dsn: window.SENTRY_DSN,
        release: window.SENTRY_RELEASE ?? null,
        environment: window.ENVIRONMENT ?? null,
        integrations: [
          new Sentry.BrowserTracing(),
          new Sentry.Replay({
            useCompression: false,
            maskAllInputs: true,
            blockAllMedia: true,
            networkDetailAllowUrls: [window.location.origin],
          })
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorsSampleRate: 1.0,
        tracesSampleRate: 0.1,
        profilesSampleRate: 1.0,
      });
    }
  });
}
import "@hotwired/turbo-rails";
import "cocoon-js-vanilla";
import "./controllers";
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;
import Masonry from "masonry-layout";
window.Masonry = Masonry;
