import ApplicationController from "./application_controller";

const micrositePathnames = [
  '/planforhope'
];

export default class extends ApplicationController {
  static targets = [
    'navbar'
  ];

  initialize() {
    this.clearActive();
    this.navbarTarget.querySelectorAll(':scope a').forEach((element) => {
      const href = element.getAttribute('href');
      if (window.location.pathname.startsWith(href)) {
        if (element.classList.contains('nav-link')) {
          element.parentNode.classList.add('active');
        } else {
          element.classList.add('active');
          element.parentNode.parentNode.classList.add('active');
        }
      }
    });

    this.hideOnMicroSites();
  }

  clearActive() {
    this.navbarTarget.querySelectorAll(':scope .active').forEach((element) => element.classList.remove('active'));
  }

  // For microsite pages, hide navbar
  hideOnMicroSites() {
    // console.log('Current pathname:', window.location.pathname, 'matching against', micrositePathnames);
    if (this.navbarTarget.classList.contains('is-microsite')) {
      // console.log('Navbar is NOT visible');
      if (micrositePathnames.indexOf(window.location.pathname) == -1) {
        this.navbarTarget.classList.remove('is-microsite');
        // console.log('NO Matches in pathname; showing navbar');
      }
    } else {
      // console.log('Navbar is visible');
      if (micrositePathnames.indexOf(window.location.pathname) > -1) {
        this.navbarTarget.classList.add('is-microsite');
        // console.log('Matches in pathname; hiding navbar');
      }
    }
  }
}
