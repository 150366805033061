import ApplicationController from "./application_controller";
import SynergyOrbLogo from '../images/synergy-logo-orb-marker.png';

export default class extends ApplicationController {
  async initMap() {
    try {
      const { Map } = await google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
      const uluru = { lat: 1.307640, lng: 103.881185 };
      const map = new Map(document.getElementById("map"), {
        center: uluru,
        zoom: 17,
        mapTypeId: 'roadmap',
        mapId: 'synergy-contact-page'
      });
      const logo = document.createElement('img');
      logo.src = SynergyOrbLogo;
      const marker = new AdvancedMarkerElement({
        position: uluru,
        map: map,
        title: 'Synergy Financial Advisers',
        content: logo
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  initialize() {
    // First check the presence of the map ID on the page
    const mapElement = document.getElementById("map");
    if (mapElement != null) {
      if (window.google != null) {
        this.initMap();
      } else {
        document.getElementById("map").innerHTML = '<div class="row justify-content-center"><div class="col-auto text-white pt-5 mt-5 display-1 text-center">Google Maps is not loaded</div></div>';
      }
    }
  }
}
