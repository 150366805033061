import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "modal",
    "image",
    "year",
    "forecast",
    "title",
  ];

  get modal() {
    return bootstrap.Modal.getOrCreateInstance(this.modalTarget);
  }

  initialize() {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl, { boundary: 'window', placement: 'bottom' }));
    // If the hash is supplied, display the modal
    if (window.location.hash) {
      const containerID = window.location.hash.substring(1);
      const container = document.getElementById(containerID);

      if (container != null) {
        this.extractContent(container);
        this.modal.show();
      }
    }

    // Determine if the language has been set via the URL param of ?language=english
    this.language = "english";
    const getParams = window.location.search.substring(1).split("&");
    getParams.forEach((match) => {
      const [key, value] = match.split("=");
      if (key === "language") {
        if (value === "chinese") {
          this.language = "chinese";
        }
      }
    });
    // Initial language to show
    if (this.language === "chinese") {
      this.showChinese();
    } else {
      this.showEnglish();
    }
  }

  showModal(event) {
    this.extractContent(event.currentTarget);
    this.modal.show();
  }

  extractContent(obj) {
    const imageElement = obj.querySelector(':scope .image');
    const data = {
      year: obj.querySelector(':scope .year')?.innerHTML,
      forecast: obj.querySelector(':scope .forecast')?.innerHTML,
      image: imageElement?.getAttribute('src'),
      title: imageElement?.getAttribute('alt'),
    };

    this.setModalContent(data);
  }

  setModalContent(data) {
    this.yearTarget.innerHTML = data.year;
    this.forecastTarget.innerHTML = data.forecast;
    this.titleTarget.innerHTML = data.title || "";
    this.imageTarget.setAttribute('src', data.image);
    this.imageTarget.setAttribute('alt', data.name);
  }

  toggleLanguage() {
    if (this.language === "chinese") {
      this.showEnglish();
    } else {
      this.showChinese();
    }
  }

  showEnglish() {
    document.querySelectorAll('.english').forEach((element) => element.style.display = '');
    document.querySelectorAll('.chinese').forEach((element) => element.style.display = 'none');
    this.language = "english";
  }

  showChinese() {
    document.querySelectorAll('.english').forEach((element) => element.style.display = 'none');
    document.querySelectorAll('.chinese').forEach((element) => element.style.display = '');
    this.language = "chinese";
  }
}
