import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["modal"];

  get modal() {
    return bootstrap.Modal.getOrCreateInstance(this.modalTarget);
  }

  options = {
    close: true,
    closeText: '<i class="fas fa-times"></i>',
    spinner: true,
    nav: true
  };
  footerClasses = ['pt-1', 'pb-2', 'justify-content-center'];

  initialize() {
    this.modalTarget.addEventListener('show.bs.modal', (event) => {
      const caller = event.relatedTarget;
      const title = caller.getAttribute('data-title');
      const caption = caller.getAttribute('data-caption') ?? '';
      const remote = caller.getAttribute('data-remote');
      const fullsize = caller.getAttribute('data-fullsize') ?? remote;
      const rawShowFooter = caller.getAttribute('data-showfooter');
      const showFooter = rawShowFooter !== 'false' && rawShowFooter !== false;
      const hasCaption = caption != null;
      const hasRemote = remote != null;

      this.modalTarget.querySelector(':scope .modal-title').innerHTML = title;

      // Align the image to center of modal body
      const modalBody = this.modalTarget.querySelector(':scope .modal-body');
      const imageHTML = hasRemote ? `<div class="flex-row"><img src="${remote}" class="img-fluid" /></div>` : '';

      // TODO: Use remote functions to pull in image data or display error
      if (hasCaption) {
        modalBody.innerHTML = `<div class="flex-row text-justify">${caption}</div>${imageHTML}`;
        modalBody.classList.remove('d-flex');
        modalBody.classList.remove('justify-content-center');
      } else {
        if (!modalBody.classList.contains('d-flex'))
          modalBody.classList.add('d-flex');
        if (!modalBody.classList.contains('justify-content-center'))
          modalBody.classList.add('justify-content-center');
        modalBody.innerHTML = `<img src="${remote}" class="img-fluid" />${imageHTML}`;
      }

      // Manage footer
      const modalFooter = this.modalTarget.querySelector(':scope .modal-footer');
      if (showFooter) {
        modalFooter.innerHTML = `<a href="${fullsize}" class="text-decoration-none"><small>Click here for full-size image</small></a>`;

        // Manipulate footer classes
        this.footerClasses.forEach((footerClass) => {
          if (!modalFooter.classList.contains(footerClass)) {
            modalFooter.classList.add(footerClass);
          }
        });
        modalFooter.style.display = '';
      } else {
        modalFooter.style.display = 'none';
      }
    });
  }
}
